import type { ThemeOptions } from '@mui/material'

export interface Incentive {
  title: string | null
  copy: string | null
}

export interface FeeRow {
  title: string
  explanation: string
}

export interface ApplicationPageCopy {
  title: Array<string>
  incentives: Array<Incentive>
  marginLow: number
  marginHigh: number
  feesRows: Array<FeeRow>
}

export interface Offer {
  title: string
  description: string
  image: string
  linkHref: string
  linkText: string
}

export interface Reward {
  title: string
  copy: string
}

export interface Links {
  homepage: string
  legal: Legal
}

export interface Legal {
  loyaltyProgram: string
  autopayAgreement: string
  cardholderAgreement: string
  ecommDisclosure: string
  autodialerAuth: string
  creditReportAuth: string
  welcomeBonus: string
}

export interface BrandInformation {
  subdomain: string | null
  brandName: string
  productName: string | null
  cardName: string
  bankPartner: string
  email: string
  phoneNumber: string
  links: Links
}

export interface Images {
  logo: {
    src: string
    width: number
    height: number
  }
  cardFrontSrc: string
}

export interface Copy extends BrandInformation {
  images: Images
  application: ApplicationPageCopy
  rewards: Array<Reward>
  offers: Offer[]
  benefits: Benefit[]
}

export interface Benefit {
  title: string
  description: string
  linkHref: string
  linkText: string
}

export enum PROGRAM_STATUS {
  Active,
  ApplicationsClosed,
}

export interface Template {
  theme: ThemeOptions
  copy: Copy
  gradient: string
  cardOrientation: 'horizontal' | 'vertical'
  programStatus: PROGRAM_STATUS
  hiddenPaths: string[]
}
